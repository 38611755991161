<template>
  <div class="welcome-container">
    <h1 class="welcome-title">
      Welcome to toTree!
    </h1>
    <p class="welcome-description">
     Here's how the process works
    </p>
    <div class="steps-container">
      <div class="step-card">
        <div class="step-number">1</div>
        <div class="step-content">Create an account</div>
        <div class="step-description"></div>
        <img src="../../assets/icons/ic-selection.svg" alt="Answer questions" class="step-image" />
        <div class="timeInfo">
          <img src="../../assets/images/time.png" alt="timer" class="quizButtonTimer" />
          <span>1 min</span>
        </div>
      </div>

      <div class="step-card">
        <div class="step-number">2</div>
        <div class="step-content">Take our style survey</div>
        <div class="step-description"></div>
        <img src="../../assets/icons/ic-furniture.svg" alt="Expert selection" class="step-image" />
        <div class="timeInfo">
          <img src="../../assets/images/time.png" alt="timer" class="quizButtonTimer" />
          <span>5-7 min</span>
        </div>
      </div>

      <div class="step-card">
        <div class="step-number">3</div>
        <div class="step-content">Get your furniture selection</div>
        <div class="step-description"></div>
        <img src="../../assets/icons/ic-moodboard.svg" alt="Furniture boards" class="step-image" />
        <div class="timeInfo">
          <img src="../../assets/images/time.png" alt="timer" class="quizButtonTimer" />
          <span>Within 72 hours</span>
        </div>
      </div>
    </div>

    <button
      class="customGreenBTN primary continue-button"
      @click="nextStep"
    >
      Continue
    </button>
  </div>
</template>

<script>
export default {
  name: 'WelcomeGrid',
  props: {
    nextStep: { type: Function }
  }
}
</script>

<style scoped>
.welcome-container {
  padding: 20px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.welcome-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.2;
}

.welcome-description {
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-bottom: 20px;
  color: #374151;
}

.survey-length {
  font-size: 16px;
  margin-bottom: 40px;
}

.time {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.steps-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  align-items: stretch;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;

}

.step-card {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.step-number {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}

.step-content {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
  text-align: center;
  max-width: 250px;
}

.step-description {
  font-size: 14px;
  color: #576b5c;
  margin-bottom: 20px;
  text-align: center;
}

.step-image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-top: auto;
}

.continue-button {
  max-width: 100%;
  width: 250px !important;
  margin: 24px auto 0 auto;
}

.continue-button:hover {
  background-color: #1a4a3f;
}

.quizButtonTimer {
  max-width: 25px;
  max-height: 25px;
}

.timeInfo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
}

@media (max-width: 909px) {
  .steps-container {
    flex-direction: column;
  }

  .step-card {
    max-width: 100%;
  }
}
</style>
