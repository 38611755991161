<template>
  <div class="inspirationsContainer">
    <h2 class="header">The Extras</h2>
    <div class="question">Anything we forgot? Any details you'd like to add about your space? Let us know!</div>
    <div class="textWrapper">
      <TextAreaTt v-model="localResponses.style_notes" question="" placeholder=""/>
    </div>
    <div class="imagesQuestion">Want to include images of your space, or existing pieces of furniture that you want to keep? The more the better!</div>
    <div class="uploadWrapper">
      <FileUpload
      v-model="localResponses.space_images"
      ref="uploader"
      question=""
      :maxFiles="4"
      />
    </div>
      <div class="imagesQuestion">Maybe you have some inspo pics? If so, upload links here:</div>
      <div class="inspiration-links">
        <TextInput
          v-for="index in numberOfInspirationLinkInputs"
          v-bind:key="index"
          v-model="localResponses.inspirationLinks[index]"
          :question="{ question: `Inspiration link ${index}`, placeholder: 'https://...' }"
          variant="outlined"
        />
        <button class="add-more-links-button" @click="addInspirationLinksRow">+ Add more links</button>
      </div>
      <PhotosPreview :images="localResponses.space_images" :onFileRemove="(file) => $refs.uploader.remove(undefined,file.id)"/>
    </div>
</template>

<script>
import * as quizData from '@/components/quiz/quizDataNew'
import PhotosPreview from '@/views/PhotosPreview.vue'

export default {
  name: 'SurveyInspirations',
  components: {
    PhotosPreview
  },
  props: {
    nextSection: {
      type: Function,
      required: true
    },
    prevSection: {
      type: Function,
      required: true
    },
    responses: {
      type: Object,
      required: true
    },
    saveSurvey: {
      type: Function,
      required: true
    }
  },
  mounted () {
    if (this.$gtm?.enabled()) {
      this.$gtm.trackEvent({
        event: 'quiz_step_view',
        section: 'Extras'
      })
    }
  },
  computed: {
    localResponses: {
      get () {
        return this.responses
      },
      set (value) {
        this.$emit('update:responses', value)
      }
    }
  },
  data () {
    return {
      quizData,
      numberOfInspirationLinkInputs: 2
    }
  },
  methods: {
    addInspirationLinksRow () {
      this.numberOfInspirationLinkInputs += 2
      for (const index in this.numberOfInspirationLinkInputs) {
        if (this.localResponses.inspirationLinks?.[index] === undefined) {
          this.localResponses.inspirationLinks[index] = ''
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.inspirationsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header{
font-weight: 700;
}

.textWrapper{
 display: flex;
 flex-direction: column;
 max-width: 100%;
 width: 900px;
}

.uploadWrapper{
 display: flex;
 flex-direction: column;
 max-width: 100%;
 width: 900px;
}

.imagesQuestion,
.question{
  display: flex;
  max-width: 500px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 24px 0;
  line-height: 24px;
}

.imagesQuestion{
  font-size: 18px;
}

.inspiration-links {
  grid-column-start: 4;
  grid-column-end: 10;
  display: flex;
  flex-flow: row wrap;
  gap: 10px;

  * {
    flex: 0 1 calc(50% - 10px);
  }
}

.add-more-links-button {
  margin-left: auto;
  padding: 5px 10px !important;
  background-color: transparent !important;
  border: none;
  color: #004021;
  font-size: 14px;
  text-transform: none;
  font-family: "Sohne", sans-serif;
  white-space: nowrap;
  flex: 0 1 0;
  height: fit-content;
}

</style>
