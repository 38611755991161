<template>
  <div v-for="(section, index) in quizData[template].filter(el=> replaceFurnitures.includes(el.sectionId) || el.sectionId=== 'color')" :key="index" >
    <div v-if="(activeQuestionIndex===index && replaceFurnitures.includes(section.sectionId) || (!replaceFurnitures.includes(section.sectionId) && activeQuestionIndex===replaceFurnitures.length && section.sectionId ==='color'))" class="container">
      <h2 class="itemHeader">{{ section.sectionTitle }}</h2>
      <div class="description" v-if="section.description">{{ section.description }}</div>
      <div v-bind:class="{ 'content' : subsection.type != 'TextAreaTt', 'textInputContent' : subsection.type === 'TextAreaTt',  hidden: subsection?.conditional && localResponses.furnitures[section.sectionId][subsection.conditional.name] !== subsection.conditional.option}"  v-for="(subsection, subsectionIndex) in section.subsections" :key="subsectionIndex">
        <div class="content" v-if="subsection.type === 'StyleRadio'">
          <span v-if="!subsection.conditional || localResponses.furnitures[section.sectionId][subsection.conditional.name] === subsection.conditional.option" class="info">{{subsection.header}}</span>
          <StyleRadio
            v-if="section.sectionId === 'color'"
            v-model="localResponses[subsection.vmodel]"
            :options="quizData[subsection.options]"
            required
            autoProceed
            :questionsRefId="subsection.questionsRefId"
            :ref="`question-${index}-${subsectionIndex}`"
            @radioClicked="handleRadioClicked"
          />
          <StyleRadio
            v-else-if="section.sectionId !== 'color' && !subsection.conditional"
            v-model="localResponses.furnitures[section.sectionId][subsection.vmodel]"
            :options="quizData[subsection.options]"
            required
            autoProceed
            :questionsRefId="subsection.questionsRefId"
            :ref="`question-${index}-${subsectionIndex}`"
            @radioClicked="handleRadioClicked"
          />
          <StyleRadio
            v-else-if="localResponses.furnitures[section.sectionId][subsection.conditional.name] === subsection.conditional.option"
            v-model="localResponses.furnitures[section.sectionId][subsection.vmodel]"
            :options="quizData[subsection.options]"
            required
            autoProceed
            :questionsRefId="subsection.questionsRefId"
            :ref="`question-${index}-${subsectionIndex}`"
            @radioClicked="handleRadioClicked"
          />
        </div>
        <div class="content" v-if="subsection.type === 'RadioTt'">
          <RadioTt
            v-if="!subsection.conditional"
            v-model="localResponses.furnitures[section.sectionId][subsection.vmodel]"
            :question="quizData[subsection.question]"
            required
            autoProceed
            :questionsRefId=subsection.questionsRefId
            :ref="`question-${index}-${subsectionIndex}`"
            @radioClicked="handleRadioClicked"
          />
          <RadioTt
            v-else-if="localResponses.furnitures[section.sectionId][subsection.conditional.name] === subsection.conditional.option"
            v-model="localResponses.furnitures[section.sectionId][subsection.vmodel]"
            :question="quizData[subsection.question]"
            required
            autoProceed
            :questionsRefId=subsection.questionsRefId
            :ref="`question-${index}-${subsectionIndex}`"
            @radioClicked="handleRadioClicked"
          />
        </div>
        <div class="content" v-if="subsection.type === 'RadioBudget'">
          <RadioBudget
            v-if="!subsection?.conditional || localResponses.furnitures[section.sectionId][subsection.conditional.name] === subsection.conditional.option"
            v-model="localResponses.furnitures[section.sectionId][subsection.vmodel]"
            :question="subsection.type_dependent ? quizData[`${section.sectionId}_${localResponses.furnitures[section.sectionId].type}_budget_options`] : quizData[subsection.question]"
            required
            autoProceed
            :questionsRefId=subsection.questionsRefId
            :ref="`question-${index}-${subsectionIndex}`"
            @radioClicked="handleRadioClicked"
          />
        </div>
        <div class="content" v-if="subsection.type === 'StyleCheckbox'">
          <StyleCheckbox
            v-if="section.sectionId !== 'color'"
            v-model="localResponses.furnitures[section.sectionId][subsection.vmodel]"
            :question="quizData[subsection.question]"
            :questionsRefId=subsection.questionsRefId
            :ref="`question-${index}-${subsectionIndex}`"
            required
          />
          <StyleCheckbox
            v-else-if="localResponses.colorIntensity && localResponses.colorIntensity !=='neutral'"
            v-model="localResponses[subsection.vmodel]"
            :question="quizData[subsection.question]"
            :questionsRefId=subsection.questionsRefId
            :ref="`question-${index}-${subsectionIndex}`"
            required
          />
        </div>
        <div v-if="subsection.type === 'TextAreaTt' && (!subsection?.conditional || localResponses.furnitures[section.sectionId][subsection.conditional.name] === subsection.conditional.option)">
          <TextInput
            v-model="localResponses.furnitures[section.sectionId][subsection.vmodel]"
            :question="subsection.question"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="activeQuestionIndex === replaceFurnitures.length + 1">
    <SurveyInspirations :saveSurvey="saveSurvey" :responses="responses" :nextSection="nextSection" :prevSection="prevSection"/>
  </div>
  <div class="quizNavigationWrapper">
    <button
    @click="prevQuestion"
    class="quiz__navigation__button quiz__navigation__button--back"
  >
    <span class="icon-arrow-left"></span> Back
   </button>
  <button
    @click="nextQuestion"
    class="quiz__navigation__button quiz__navigation__button--continue primary customGreenBTN"
    >
    <span>Continue</span>
    <span class="icon-arrow-right"></span>
  </button>
  </div>

</template>

<script>

import * as quizData from '@/components/quiz/quizDataNew'
import SurveyInspirations from '@/views/SurveyInspirations.vue'

export default {
  created () {
    const sections = this.quizData[this.template]
    sections.forEach(section => {
      if (section.sectionId !== 'color' && !this.localResponses?.furnitures[section.sectionId]) {
        this.localResponses.furnitures[section.sectionId] = {}
      }
      section.subsections.forEach(subsection => {
        if (section.sectionId !== 'color' && !(subsection.vmodel in this.localResponses?.furnitures[section.sectionId])) {
          this.localResponses.furnitures[section.sectionId][subsection.vmodel] = ''
        }
      })
    })

    if (this.resumeSurvey) {
      this.determineStartingQuestion()
    }
  },
  name: 'RoomQuestions',
  props: {
    template: {
      type: String,
      required: true
    },
    nextSection: {
      type: Function,
      required: true
    },
    prevSection: {
      type: Function,
      required: true
    },
    responses: {
      type: Object,
      required: true
    },
    saveSurvey: {
      type: Function,
      required: true
    },
    resumeSurvey: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SurveyInspirations
  },
  computed: {
    localResponses: {
      get () {
        return this.responses
      },
      set (value) {
        this.$emit('update:responses', value)
      }
    }
  },
  data () {
    return {
      activeQuestionIndex: 0,
      quizData,
      handleRadioClicked: () => {},
      replaceFurnitures: this.responses?.replace_furnitures
    }
  },
  async mounted () {
    const questions = this.quizData[this.template].filter(el => this.replaceFurnitures.includes(el.sectionId) || el.sectionId === 'color')

    if (this.$gtm?.enabled() && questions[0]?.sectionId) {
      this.$gtm.trackEvent({
        event: 'quiz_step_view',
        section: questions[0]?.sectionId
      })
    }
  },
  methods: {
    determineStartingQuestion () {
      let completedFurnitureCount = 0

      for (const furnitureId of this.replaceFurnitures) {
        const furnitureData = this.localResponses.furnitures[furnitureId]

        if (furnitureData && furnitureData.budget && furnitureData.budget !== '') {
          completedFurnitureCount++
        }
      }

      this.activeQuestionIndex = completedFurnitureCount

      if (this.activeQuestionIndex >= this.replaceFurnitures.length) {
        if (this.localResponses.colorIntensity) {
          this.activeQuestionIndex = this.replaceFurnitures.length + 1
        }
      }
    },

    async validate () {
      let allValid = true
      const sections = this.quizData[this.template]
      for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
        const section = sections[sectionIndex]
        for (let subsectionIndex = 0; subsectionIndex < section.subsections.length; subsectionIndex++) {
          const refKey = `question-${sectionIndex}-${subsectionIndex}`
          const component = this.$refs[refKey]

          if (component && component[0]?.validate) {
            const isValid = await component[0].validate()

            if (!isValid) {
              allValid = false
              component[0].$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
              break
            }
          }
        }
        if (!allValid) break
      }
      return allValid
    },

    async handleNextSection () {
      if (await this.validate()) {
        this.nextSection()
      }
    },

    async nextQuestion () {
      if (!(await this.validate())) {
        return
      }

      setTimeout(() => window.scrollTo(0, 0), 100)
      this.activeQuestionIndex++

      if (this.activeQuestionIndex === this.replaceFurnitures.length + 2) {
        this.handleNextSection()
      } else {
        const questions = this.quizData[this.template].filter(el => this.replaceFurnitures.includes(el.sectionId) || el.sectionId === 'color')

        if (this.$gtm?.enabled() && questions[this.activeQuestionIndex]?.sectionId) {
          this.$gtm.trackEvent({
            event: 'quiz_step_view',
            section: questions[this.activeQuestionIndex]?.sectionId
          })
        }

        this.saveSurvey()
      }
    },
    prevQuestion () {
      if (this.activeQuestionIndex === 0) {
        this.prevSection()
      } else {
        this.activeQuestionIndex--
        setTimeout(() => window.scrollTo(0, 0), 100)
      }
    }
  }
}
</script>

<style scoped>

.container {
display: flex;
flex-direction: column;
align-items: center;
padding-top: 40px;
font-family: "Sohne";
font-size: 16px;
color: #0b1326;
margin-left: auto;
margin-right: auto;
width: 100%;
flex: 1;
}

.content{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin: 20px 0;
}

.styleNavigation{
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 800px;

}

.description{
max-width: 600px;
}

.header{
font-size: 40px;
font-weight: 500;
padding: 20px 0 20px 0;
}

.draperGallery{
display: flex;
flex-direction: row;
flex-wrap: wrap;
gap: 10px;
}

.draperImage{
flex: 1 1 calc(33.33% - 1rem);
max-width: calc(33.33% - 1rem);
}

.itemHeader{
font-size: 45px;
font-weight: 600;
padding-bottom: 10px;
}

.info{
font-family: "Sohne";
color: #0b1326;
font-size: 18px;
font-weight: 400;
margin-bottom: 20px;
}

.description{
text-align: center
}

.hidden{
display: none;
}

.textInputContent{
display: flex;
max-width: 531px;
width: 100%;
}
</style>
