<template>
  <main class="main page newpagechnge quizStep firstStepSliderPaddingBottom">
    <div class="quiz quiz-registration">
      <div class="quiz-content">
        <template v-if="quizData">
          <transition name="fade" mode="out-in">
            <!--
               ______   _______  _______ _________ _______  _______
              (  ___ \ (  ___  )(  ____ \\__   __/(  ____ \(  ____ \
              | (   ) )| (   ) || (    \/   ) (   | (    \/| (    \/
              | (__/ / | (___) || (_____    | |   | |      | (_____
              |  __ (  |  ___  |(_____  )   | |   | |      (_____  )
              | (  \ \ | (   ) |      ) |   | |   | |            ) |
              | )___) )| )   ( |/\____) |___) (___| (____/\/\____) |
              |/ \___/ |/     \|\_______)\_______/(_______/\_______)
            -->

                <transition name="fade" mode="out-in" v-if="currentSectionIndex === 1">
                          <transition name="fade" mode="out-in">
                            <div>
                                  <HeadingTt textAlign="center">
                                  <QuizIntro :next-step="nextSection"/>
                                  </HeadingTt>
                            </div>
                        </transition>
                </transition>

                <transition name="fade" mode="out-in" v-else-if="!isUserLoggedIn()">
                      <div>
                        <transition name="fade" mode="out-in">
                            <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                          </transition>
                          <HeadingTt textAlign="center">
                            We like to get familiar with our clients (and it’s how we save your answers!)
                          </HeadingTt>
                          <RegistrationNew v-model="responses.userData" ref="UserRegistration" required :next-step="nextSection" :prev-step="prevSection" :set-page-loading="(value) => loading=value"/>
                      </div>
              </transition>

                <transition name="fade" mode="out-in" v-else-if="currentSectionIndex === 2">
                      <div >
                          <!-- Type of Furniture -->
                    <transition name="fade" mode="out-in">
                      <h1 class="quiz__heading" :key="sectionHeading">{{ sectionHeading }}</h1>
                    </transition>
                    <RadioTt
                      v-model="responses.type_of_furniture"
                      :question="quizData.type_of_furniture"
                      ref="q-0"
                      required
                      @change="changeFurnitureType"
                    />
                    <transition name="fade" mode="out-in">
                      <!-- Living room furniture -->
                      <Checkbox
                        v-if="responses?.type_of_furniture === 'living-room'"
                        v-model="responses.replace_furnitures"
                        :question="quizData.replace_living_room"
                        ref="q-1"
                        required
                      />
                      <!-- Bedroom furniture -->
                      <Checkbox
                        v-else-if="responses?.type_of_furniture === 'bedroom'"
                        v-model="responses.replace_furnitures"
                        :question="quizData.replace_bedroom"
                        ref="q-2"
                        required
                      />
                      <!-- Dining room furniture -->
                      <Checkbox
                        v-else-if="responses?.type_of_furniture === 'dining-room'"
                        v-model="responses.replace_furnitures"
                        :question="quizData.replace_dining_room"
                        ref="q-3"
                        required
                      />
                      <!-- Office furniture -->
                      <Checkbox
                        v-else-if="responses?.type_of_furniture === 'office'"
                        v-model="responses.replace_furnitures"
                        :question="quizData.replace_office"
                        ref="q-4"
                        required
                      />
                    </transition>
                    <transition>
                      <div>
                          <RadioTt
                          v-model="responses.your_timeline"
                          :question="quizData.your_timeline"
                          ref="q-5"
                          questionsRefId="q-5"
                          required
                          :menu-type="'timeline'"
                         />
                      <!-- Timeline notes -->
                      <TextAreaTt
                        v-model="responses.timeline_notes"
                        :question="quizData.timeline_notes"
                      />
                      <fieldset class="form__question textarea-div inputField">
                        <div class="form__content">
                            <TextInput
                                v-model="responses.project_name"
                                :question="{ question: 'Project Name', placeholder: 'Project name' }"
                                variant="outlined"
                            />
                        </div>
                      </fieldset>
                      </div>

                    </transition>
                    <div class="quizNavigationWrapper">
                      <button
                        :disabled=true
                        @click="prevSection"
                        class="quiz__navigation__button quiz__navigation__button--back"
                      >
                        <span class="icon-arrow-left"></span> Back
                      </button>
                      <button
                        @click="nextSection"
                        class="quiz__navigation__button quiz__navigation__button--continue primary customGreenBTN"
                      >
                        Continue
                        <span class="icon-arrow-right"></span>
                    </button>
                   </div>
                  </div>
                </transition>

                  <transition v-else-if="currentSectionIndex === 3">
                    <div>
                    <transition name="fade" mode="out-in">
                      <h1 class="quiz__heading" >The Style</h1>
                    </transition>
                    <!-- Style selector -->
                    <HeadingTt textAlign="center">
                      Here's where you select your preferred style of furniture.<br/><br/>
                      We offer foundational furniture in three timeless styles: Mid-Century Modern, Industrial, and California Coastal. To help you choose a style, here are examples of rooms we love and examples of individual furniture pieces that we offer at toTree.<br/><br/>
                      Simply scroll through all three style sections - Draper, Moody and Roy - and click <span class="highlighted">'My Choice'</span> to select your favorite and then click <span class="highlighted">'Continue.'</span>
                    </HeadingTt>
                    <StyleSelector
                      :typeOfFurniture="responses.type_of_furniture"
                      v-model="responses.favorite_styles"
                      required
                      ref="q-0"
                    />
                      <div class="quizNavigationWrapper stylesNavigation">
                        <button
                          @click="prevSection"
                          class="quiz__navigation__button quiz__navigation__button--back"
                        >
                          <span class="icon-arrow-left"></span> Back
                        </button>
                        <button
                          @click="nextSection"
                          class="quiz__navigation__button quiz__navigation__button--continue primary customGreenBTN"
                        > Continue
                          <span class="icon-arrow-right"></span>
                        </button>
                      </div>
                    </div>
                  </transition>
                  <transition v-else-if="currentSectionIndex === 4">
                    <div v-if="responses.type_of_furniture === 'living-room'">
                      <RoomQuestions :resumeSurvey="this.resumeSurvey" :saveSurvey="saveSurvey" :responses="responses" :nextSection="nextSection" :prevSection="prevSection" template="living_room_template"/>
                    </div>
                    <div v-else-if="responses.type_of_furniture === 'bedroom'">
                      <RoomQuestions :resumeSurvey="this.resumeSurvey" :saveSurvey="saveSurvey" :responses="responses" :nextSection="nextSection" :prevSection="prevSection" template="bedroom_template"/>
                    </div>
                    <div v-else-if="responses.type_of_furniture === 'dining-room'">
                      <RoomQuestions :resumeSurvey="this.resumeSurvey" :saveSurvey="saveSurvey" :responses="responses" :nextSection="nextSection" :prevSection="prevSection" template="dining_room_template"/>
                    </div>
                  </transition>
                  <div v-else-if="currentSectionIndex === 5">
                    <transition name="fade" mode="out-in">
                      <!-- Checkout -->
                      <div>
                        <EmbeddedCheckout :nextStep="nextSection"/>
                    </div>
                    </transition>
                  </div>
            <!-- Quiz End -->
          </transition>
        </template>
      </div>
      <Loader :visible="loading" fixed/>
      <div v-if="initialLoad" class="quiz__loader-cover"/>

      <transition name="fade">
        <div v-if="showInProgressDialog" class="quiz__in-progress-overlay">
          <h1 class="quiz__heading">Survey in Progress</h1>
          <div class="form__question">
            <div class="form__content">
              <p class="quiz__subheading">You started a survey already; would you like to continue it, or start
                a new one?</p>
              <div class="form__actions">
                <button class="primary customGreenBTN" @click="continueInProgressQuiz">
                  Continue Survey
                </button>
                <button class="block btn-Border" @click="showInProgressDialog = false">
                  Start New Project
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showDebug" class="button--debug">
      <a @click="validate = !validate">Toggle Validation | Currently Active?: {{ validate }}</a>
    </div>
  </main>

</template>

<!-- eslint-disable vue/no-unused-components -->

<script>
import * as quizData from '@/components/quiz/quizDataNew'
import QuizStepWrapper from '@/components/quiz/QuizStepWrapper'
import QuizIntro from '@/components/quiz/QuizIntro'
import RoomQuestions from '@/components/quiz/RoomQuestions'

import Loader from '@/components/Loader'
// import { httpsCallable } from 'firebase/functions'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { nextTick } from 'vue'
import RegistrationNew from '../components/quiz/questions/RegistrationNew.vue'
import RadioTt from '@/components/quiz/questions/RadioTt.vue'
import Checkbox from '@/components/quiz/questions/Checkbox.vue'
import HeadingTt from '@/components/quiz/questions/HeadingTt.vue'
import TextAreaTt from '@/components/quiz/questions/TextAreaTt.vue'
import StyleSelector from '@/components/quiz/questions/StyleSelector.vue'
import StyleRadio from '@/components/quiz/questions/StyleRadio.vue'
import TextInput from '@/components/quiz/questions/TextInput.vue'
import StyleRadioSlides from '@/components/quiz/questions/StyleRadioSlides.vue'
import Slider from '@/components/quiz/questions/Slider.vue'
import FileUpload from '@/components/quiz/questions/FileUpload.vue'
// import QuizProgressBar from '@/components/quiz/QuizProgressBar.vue'
// import StyleBoardsSneakPeak from '@/components/quiz/StyleBoardsSneakPeak.vue'
import PhotosAndMeasurements from '@/components/quiz/PhotosAndMeasurements/PhotosAndMeasurements.vue'
import EmbeddedCheckout from '@/components/quiz/EmbeddedCheckout.vue'
import PhotosPreview from '@/views/PhotosPreview.vue'
import SurveySummary from '../components/quiz/SurveySummary.vue'
import SurveyInspirations from '@/views/SurveyInspirations.vue'

export default {
  name: 'Quiz',
  components: {
    PhotosPreview,
    EmbeddedCheckout,
    PhotosAndMeasurements,
    QuizStepWrapper,
    QuizIntro,
    RoomQuestions,
    SurveySummary,
    Loader,
    RegistrationNew,
    RadioTt,
    Checkbox,
    HeadingTt,
    TextAreaTt,
    StyleSelector,
    StyleRadio,
    TextInput,
    StyleRadioSlides,
    Slider,
    FileUpload,
    SurveyInspirations
  },
  data () {
    return {
      isModalVisible: false,
      activeStep: 0,
      activeSectionIndex: 0,
      projectName: '',
      projectAddress: {},
      photosRoom: null,
      floorPlans: null,
      spaceimages: null,
      rendrInvitationId: null,
      photos: {
        photosRoom: null,
        floorPlans: null
      },
      responses: {
        project_name: '',
        userData: {
          first_name: '',
          email: ''
        },
        style_notes: '',
        inspirationLinks: [],
        space_images: [],
        color: [],
        colorIntensity: null,
        furnitures: {
          sofa: {
            type: '',
            style: '',
            materials: '',
            budget: ''
          },
          lounge_chair: {
            type: '',
            sub_type: '',
            materials: '',
            budget: ''
          },
          sideboard: {
            type: '',
            budget: ''
          },
          coffee_table: {
            type: '',
            shape: '',
            budget: ''
          },
          end_table: {
            type: '',
            shape: '',
            budget: ''
          },
          rug: {
            sub_type: '',
            shape: '',
            budget: ''
          },
          bed: {
            type: '',
            sub_type: '',
            budget: ''
          },
          dresser: {
            type: '',
            budget: ''
          },
          nightstand: {
            type: '',
            materials: '',
            budget: ''
          },
          bench: {
            type: '',
            materials: '',
            budget: ''
          },
          dining_table: {
            type: '',
            shape: '',
            budget: ''
          },
          dining_chair: {
            type: '',
            materials: '',
            budget: ''
          },
          stools: {
            size: '',
            type: '',
            materials: '',
            budget: ''
          }
        }
      },
      quizData,
      validate: true,
      initialLoad: true,
      showInProgressDialog: false,
      loading: false,
      quizComplete: false,
      surveyBtn: false,
      initialSurvey: false,
      userLoggedIn: false,
      numberOfInspirationLinkInputs: 2,
      sections: ['offerings', 'basics', 'basics2', 'room'],
      currentSectionIndex: this.$route?.query?.onboarding ? 1 : 2,
      showRegistrationForm: false,
      hasListener: false,
      resumeSurvey: true
    }
  },
  computed: {
    ...mapState(['firestore', 'fbFunctions', 'showDebug']),
    ...mapGetters(['currentUser', 'userProfile', 'inProgressSurveys', 'targetSurvey']),
    surveyId () {
      return this.targetSurvey?.id
    },
    sectionHeading () {
      return this.currentSectionIndex === 2 ? 'The Basics' : this.currentSectionIndex === 3 ? 'The Style' : ''
    }

  },

  async created () {
    this.loading = true
    this.setTargetSurvey(null)
    await this.checkForTargetSurvey()
    this.loading = false
  },

  async mounted () {
    await nextTick()

    if (!this.targetSurvey) {
      await this.checkForTargetSurvey()
    }

    // A bit hacky, but we're just waiting for the transition to finish here if there's a target survey to load
    if (this.$route.query.id) {
      setTimeout(() => {
        this.initialLoad = false
      }, 300)
    } else {
      this.initialLoad = false
    }

    if (!this.hasListener) {
      window.addEventListener('beforeunload', this.handleBeforeUnload)
      window.addEventListener('popstate', this.handleBackButton)
      this.hasListener = true
      history.pushState({ initial: true }, '', window.location.href)
    }
  },
  beforeUnmount () {
    if (this.hasListener) {
      window.removeEventListener('beforeunload', this.handleBeforeUnload)
      window.removeEventListener('popstate', this.handleBackButton)
      this.hasListener = false
    }
  },
  methods: {
    ...mapMutations([
      'setCurrentUser',
      'setUserProfile',
      'setTargetSurvey',
      'setGlobalError',
      'setGlobalNotification'
    ]),
    ...mapActions([
      'initFirestoreSurveysData',
      'initFirestoreFromMiddelSurveysData',
      'updateFirestoreUserProfile',
      'updateFirestoreCurrentQuiz',
      'getMySurveys',
      'getTargetSurvey'
    ]),
    resetFurnituresValues () {
      for (const furniture in this.responses.furnitures) {
        for (const prop in this.responses.furnitures[furniture]) {
          this.responses.furnitures[furniture][prop] = ''
        }
      }
    },
    changeFurnitureType () {
      // If the space is changed, we don't want to keep furniture selections specific to the previous room
      this.resetFurnituresValues()
      this.responses.replace_furnitures = []
    },

    /**
     * When we land here, we're going to check to see if we need to load any quiz,
     * or if the user has any in progress quizzes. Otherwise, we'll make a new one.
     */
    async checkForTargetSurvey () {
      // First, check the URL to see if the user is targeting a specific quiz...
      if (this.$route.query.id) {
        await this.getTargetSurvey({ id: this.$route.query.id, setState: true })
        this.loadTargetSurveyResponses()
      } else if (this.$route.query.utm_medium) {
        await this.getTargetSurvey({ id: this.$route.query.utm_medium, setState: true })
        this.loadTargetSurveyResponses()
        await this.nextStep()
      } else if (!this.$route.query.new) {
        if (this.userLoggedIn) {
          if (!this.mySurveys) {
            await this.getMySurveys()
          }
          if (this.inProgressSurveys?.length) {
            this.showInProgressDialog = true
          }
        }
      }
    },

    async continueInProgressQuiz () {
      await this.getTargetSurvey({ id: this.inProgressSurveys[0].id, setState: true })
      this.loadTargetSurveyResponses()
      if (this.$gtm?.enabled() && this.currentSectionIndex < 4) {
        this.$gtm.trackEvent({
          event: 'quiz_step_view',
          section: this.sectionHeading
        })
      }
      this.showInProgressDialog = false
    },
    addInspirationLinksRow () {
      this.numberOfInspirationLinkInputs += 2
      for (const index in this.numberOfInspirationLinkInputs) {
        if (this.responses.inspirationLinks?.[index] === undefined) {
          this.responses.inspirationLinks[index] = ''
        }
      }
    },
    loadTargetSurveyResponses () {
      this.projectName = this.targetSurvey?.projectName || ''
      this.projectAddress = this.targetSurvey?.projectAddress || {}

      const dbResponses = this.targetSurvey?.responses

      const updatedResponses = {
        project_name: this.targetSurvey?.responses?.project_name || '',
        userData: {
          first_name: this.targetSurvey?.responses?.userEmail || '',
          email: this.targetSurvey?.responses?.userEmail || ''
        },
        style_notes: this.targetSurvey?.responses?.style_notes || '',
        inspirationLinks: [],
        space_images: [],
        color: [],
        colorIntensity: null,
        furnitures: { ...this.responses.furnitures }, // Clone current furnitures
        type_of_furniture: dbResponses.room.replace('_', '-'),
        replace_furnitures: [],
        your_timeline: '',
        timeline_notes: '',
        favorite_styles: []
      }

      // Reset furniture values based on the room type
      this.resetFurnituresValues(updatedResponses.furnitures)

      // Handle timeline
      if (dbResponses.timeline) {
        updatedResponses.your_timeline = dbResponses.timeline === 'rush' ? 1 : 0
      }

      // Handle additional info/timeline notes
      if (dbResponses.additional_info) {
        updatedResponses.timeline_notes = dbResponses.additional_info
      }

      // Handle style/favorite_styles
      if (dbResponses.style && Array.isArray(dbResponses.style)) {
        updatedResponses.favorite_styles = [...dbResponses.style]
      }

      // Handle color_intensity/colorIntensity
      if (dbResponses.color_intensity) {
        updatedResponses.colorIntensity = dbResponses.color_intensity
      }

      // Handle colors_v2/color
      if (dbResponses.colors_v2 && Array.isArray(dbResponses.colors_v2)) {
        updatedResponses.color = [...dbResponses.colors_v2]
      }

      // Handle replace_furnitures - critically important!
      if (dbResponses.furnitures && Array.isArray(dbResponses.furnitures)) {
        updatedResponses.replace_furnitures = [...dbResponses.furnitures]
      }

      // Handle furniture details
      if (dbResponses.details && Array.isArray(dbResponses.details)) {
        // Process each furniture item
        dbResponses.details.forEach(item => {
          if (item.kind && updatedResponses.furnitures[item.kind]) {
            const { kind, ...properties } = item

            // Copy properties from details to the furnitures object
            Object.keys(properties).forEach(prop => {
              updatedResponses.furnitures[item.kind][prop] = properties[prop]
            })
          }
        })
      }

      // Handle inspiration_links
      if (dbResponses.inspiration_links) {
        // Split the links by double newlines and filter out empty entries
        const links = dbResponses.inspiration_links.split('\n\n').filter(link => link.trim() !== '')
        updatedResponses.inspirationLinks = links

        // Update the number of input fields to match the number of links
        this.numberOfInspirationLinkInputs = Math.max(2, links.length)
      }

      // Handle space_images
      if (dbResponses.space_images) {
        try {
          // If it's a string (JSON), parse it
          if (typeof dbResponses.space_images === 'string') {
            // Handle multiple images separated by newlines
            const imagesJsonStrings = dbResponses.space_images.split('\n\n')

            updatedResponses.space_images = imagesJsonStrings
              .map(jsonStr => {
                try {
                  return JSON.parse(jsonStr)
                } catch (e) {
                  console.error('Error parsing space image JSON:', e)
                  return null
                }
              })
              .filter(img => img !== null)
          } else if (Array.isArray(dbResponses.space_images)) { // If it's already an array, use it directly
            updatedResponses.space_images = [...dbResponses.space_images]
          }
        } catch (e) {
          console.error('Error processing space_images:', e)
        }
      }

      // Set the responses object in one atomic operation
      this.responses = updatedResponses

      if (this.resumeSurvey && (this.responses.your_timeline === 0 || this.responses.your_timeline === 1) && this.responses.type_of_furniture && this.responses.replace_furnitures.length > 0) {
        if (this.responses.favorite_styles.length > 0) {
          this.currentSectionIndex = 4
        } else {
          this.currentSectionIndex = 3
        }
      }
    },

    /**
     * Loops through all elements in the DOM and runs a validate()
     * function if it's available.
     */
    async validateStep () {
      let allValid = true
      if (!this.validate) return allValid

      for (const i in this.$refs) {
        if (this.$refs[i]?.validate) {
          if (!(await this.$refs[i].validate())) {
            // this.$refs[i].$el.scrollIntoView({ behavior: 'smooth' })
            allValid = false
          }
        }
      }

      return allValid
    },

    async saveSurvey () {
      const responsesObj = {
        project_name: this.responses.project_name || this.responses.type_of_furniture.replace('-', '_'),
        room: this.responses.type_of_furniture.replace('-', '_'),
        timeline: this.responses.your_timeline === 1 ? 'rush' : 'no_rush',
        additional_info: this.responses.timeline_notes || '',
        style: this.responses.favorite_styles,
        color_intensity: this.responses.colorIntensity,
        colors_v2: this.responses.colorIntensity !== 'neutral' ? this.responses.color : [],
        version: 'v2',
        furnitures: this.responses.replace_furnitures,
        inspiration_links: this.responses.inspirationLinks.length > 0 ? this.responses.inspirationLinks.join('\n\n') : '',
        space_images: this.responses.space_images.length > 0 ? this.responses.space_images.map(obj => JSON.stringify(obj, null, 2))
          .join('\n\n') : '',
        style_notes: this.responses.style_notes,
        details: Object.entries(this.responses.furnitures).map(([kind, properties]) => {
          return {
            kind: kind,
            ...properties
          }
        }).filter(el => el.budget?.length > 0)
      }

      const payload = {
        responses: responsesObj,
        inProgress: this.$route.query?.completed ? false : this.currentSectionIndex <= 5,
        isPending: this.$route.query?.completed === 'true' || this.currentSectionIndex === 6
      }

      if (this.currentUser?.uid) {
        await this.updateFirestoreCurrentQuiz(payload)
      }
    },

    isUserLoggedIn () {
      return this.currentUser?.uid != null
    },

    async nextSection (validate = true) {
      if (!validate || !(await this.validateStep())) {
        return
      }

      this.currentSectionIndex++

      if (this.$gtm?.enabled() && this.currentSectionIndex < 4) {
        this.$gtm.trackEvent({
          event: 'quiz_step_view',
          section: this.sectionHeading
        })
      }

      if (this.currentSectionIndex === 5) {
        this.saveSurvey()
      }
      if (this.currentSectionIndex === 6) {
        this.saveSurvey()
        this.$router.push({
          path: '/quiz-complete',
          query: {
            data: btoa(JSON.stringify({ ...this.responses, surveyId: this.surveyId }))
          }
        })
      }
      setTimeout(() => window.scrollTo(0, 0), 100)
    },
    prevSection () {
      this.resumeSurvey = false
      this.currentSectionIndex--
      setTimeout(() => window.scrollTo(0, 0), 100)
    },
    handleBeforeUnload (e) {
      e.preventDefault()
      e.returnValue = 'If you leave, all your survey progress will be lost. Are you sure you want to exit?'
      return e.returnValue
    },
    handleBackButton (e) {
      if (!confirm('If you leave, all your survey progress will be lost. Are you sure you want to exit?')) {
        history.pushState(null, '', window.location.href)
      }
    }

  }

}

</script>

<style lang="scss" scoped>
.quiz {
  &__loader-cover {
    @include fill-parent;
    z-index: 100;
    background-color: white;
  }

  &__in-progress-overlay {
    @include fill-parent;
    padding: calc(var(--leading) * 2) var(--gutter);
    background-color: white;

    .form {
      &__actions {
        margin-top: var(--leading);
        grid-column: span 12;

        button {
          width: calc(50% - (var(--gutter) / 2));
          white-space: nowrap;
        }
      }
    }
  }
}

.long-loader {
  text-align: center;
  position: absolute;
  top: 154px;
  width: 100%;
  height: calc(100vh - 250px);
  background-color: $tt-bright-white;
  padding: calc(var(--leading) * 2) 0;

  @include breakpoint($s) {
    top: 114px;
    height: calc(100vh - 200px); // see form__question height in Payment.vue
  }

  &__content {
    @include meta-text;
    max-width: 300px;
    margin: 0 auto;

    @include breakpoint($s) {
      max-width: 200px;
    }

    img {
      width: 150px;

      @include breakpoint($s) {
        width: 100px;
      }
    }

    p {
      padding: var(--leading) 0;
    }
  }
}

@media (max-width: 583px) {
  .quiz .quiz__navigation__button {
    height: 40px !important;
    padding: 10px !important;
  }
}

.inspiration-links {
  grid-column-start: 4;
  grid-column-end: 10;
  display: flex;
  flex-flow: row wrap;
  gap: 10px;

  * {
    flex: 0 1 calc(50% - 10px);
  }
}

.add-more-links-button {
  margin-left: auto;
  padding: 5px 10px !important;
  background-color: transparent !important;
  border: none;
  color: #004021;
  font-size: 14px;
  text-transform: none;
  font-family: "Sohne", sans-serif;
  white-space: nowrap;
  flex: 0 1 0;
  height: fit-content;
}

.stylesNavigation{
  margin-top: 50px;
  z-index: 999;
}

.highlighted{
  font-weight: 600;
}

.inputField{
  margin-top: -32px;
}

</style>
